<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="申请单号" :initialContent="detail.code" :disabled="true"></inputItem>
        <inputItem title="申请人员" :initialContent="detail.apply_name" :disabled="true"></inputItem>
        <inputItem title="所属部门" :initialContent="detail.dept" :disabled="true"></inputItem>
        <inputItem title="申请时间" :initialContent="formatDate(detail.apply_time)" :disabled="true"></inputItem>
        <inputItem title="交流时间" type="date" name="time" :initialContent="formatDate(detail.time)" :contentChanged="contentChanged" :disabled="disabled"></inputItem>
        <inputItem title="项目名称" type="others" ref="project_name" :initialContent="detail.project_name" :otherClicked="clickProject" :disabled="disabled"></inputItem>
        <inputItem title="交流内容" v-model="detail.content" :disabled="disabled"></inputItem>
        <inputItem title="预算金额" v-model="detail.total" :disabled="disabled"></inputItem>
        <div class="mobile-detail-step" v-if="detail && detail.state > 1">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawer" v-if="showOptions" :onBgClicked="onBgClicked" :onSearch="onSearch" :items="projects" @optionClicked="optionClicked">
    </optionDrawer>
  </div>
</template>

<script>
import { GetEntertain, AuditEntertain, SaveEntertain, CancelEntertain } from "@/api";
import { loadDetailAction, saveAction, auditAction, cancelAction, loadProjectOptionAction } from "@/utils/action.js";
import { formatDate, formatMoney } from "@/utils/format.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      flows: [],
      buttons: [],
      projects: [],
      showOptions: false,
      disabled: false,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    onSearch: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClicked: function () {
      this.showOptions = false;
    },
    optionClicked: function (item) {
      console.log(item);
      this.detail.project_id = item.id;
      this.detail.project_name = item.name;
      this.$refs.project_name.setContent(item.name);
      this.showOptions = false;
    },
    clickProject: function () {
      this.showOptions = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.optionDrawer.show();
        }, 100);
      });
    },
    contentChanged: function (index, value) {
      switch (index) {
        case "time":
          this.detail.time = value;
          break;
      }
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveEntertain(1);
          break;
        case "提交审核":
          this.saveEntertain(2);
          break;
        case "审核通过":
          this.auditEntertain(true, "同意");
          break;
        case "审核拒绝":
          this.auditEntertain(false, "");
          break;
        case "撤回":
          this.cancelEntertain();
          break;
      }
    },
    saveEntertain: function (state) {
      this.detail.state = state;
      saveAction(this, SaveEntertain, { model: this.detail }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; });
    },
    auditEntertain: function (pass, memo) {
      auditAction(this, AuditEntertain, this.id, pass, memo);
    },
    cancelEntertain: function () {
      cancelAction(this, CancelEntertain, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetEntertain, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>